import Booking from 'models/Booking'

const getObject = (booking) =>
  new Booking(
    booking.name,
    booking.email,
    booking.phone,
    booking.remark,
    booking.meta,
    booking.start,
    booking.end,
    booking.status,
    booking.slotTimetableId,
    booking.userId,
    booking.slot,
    booking.fee,
    booking.paidAmount,
    booking.id
  )

export default getObject
